// SingleEvent.js
import React, { useState } from "react";
import '../../App.css';
import ArticleCard from "../ArticleCard";
import MUICard2 from "../MUICard2";
import { Box } from "@mui/material";
import MUISingleEventCard from "../MUISingleEventCard";

function SingleEvent5() {

    const [heroSectionEventData] = useState({
        image: "images/Georgian-Music/2024-2025/gm_13-04-25.jpg",
        title: 'The World of Yesterday',
        subtitle: 'Piano Duo',
        date: 'Sunday 13 April 2025 3.00pm ',
        location: 'Bethel Community Church, 128 Vincent St., Barrie',
        buttonText: 'Book Tickets',
         buttonLink: 'https://www.showpass.com/bca-georgian-music-sun-series-2024-2025/'
    });

    const [eventData] = useState({
        program: `
Welcome to The World of Yesterday, a captivating journey through Vienna's rich musical legacy, inspired by the memoirs of Stefan Zweig. This program showcases some of the most beloved compositions from the 18th to 20th centuries, brought to life through both solo piano and one piano, four hands. Audiences will be treated to enchanting piano transcriptions of J. Strauss's "The Beautiful Blue Danube" and "Fledermaus," alongside Ravel's mesmerizing "La Valse," arranged for one piano, four hands. With works from iconic Viennese composers Schubert, Mozart, and Alban Berg, this evening promises to transport you to a bygone era, where the elegance and charm of Vienna's musical golden age still resonate deeply. Enjoy an unforgettable evening filled with timeless beauty and inspiration.`,
        performers: `Lithuanian-born pianist Agnė Radzevičiutė has garnered international acclaim for her "sincere and meaningful" interpretations and "exquisite musical sensitivity" (Seven Days of Art). Her performances have been broadcast on Mezzo TV and have taken her to prestigious stages across Europe, North America, and beyond. A prizewinner of numerous international piano competitions, Agnė recently secured 3rd Prize at the National Chopin Piano Competition in Toronto, granting her entry to the 18th International F. Chopin Competition in Warsaw. She has performed with esteemed orchestras such as the Lithuanian Chamber Orchestra and debuted with the Lithuanian National Symphony Orchestra in 2023. Agnė holds an Artist Diploma from the Glenn Gould School in Toronto and continues to perform and teach, maintaining an active musical career alongside her pianist husband, Dmitri Levkovich.`,
        performers2: 'Ukrainian-born Canadian pianist and composer Dmitri Levkovich is renowned for his "artistic sophistication far above the ordinary" (Frankfurter Allgemeine Zeitung) and his "understanding of the music far beyond most young pianists” (American Record Guide). His recent performances include Grieg’s Concerto with the Dresden Philharmonic, Liszt’s First Piano Concerto at the Berlin Philharmonie, and a debut at the Ravinia Festival. Dmitri has appeared in ARTE TV’s "Stars of Tomorrow" and earned acclaim for his recording of Rachmaninoff’s 24 Preludes, which was nominated for the 2016 International Classical Music Award. He has performed with orchestras such as the Cleveland Orchestra, Dresden Philharmonic, and Mariinsky Theatre, under esteemed conductors like Valery Gergiev and Vladimir Spivakov. Dmitri is a laureate of over 20 international piano competitions and has received numerous audience favorite and special prizes. As a composer, his works have been praised for their emotional depth, and his pieces are gaining recognition worldwide. In 2024, his "Fantasie" will be performed by the Lithuanian National Symphony Orchestra, and his Piano Trio will debut at the Verbier Festival in Switzerland.',
        performerLink: 'www.agneradzeviciute.com',
        ticketInfo: `Purchase your season subscription early. 5 amazing upcoming concerts. Adults Live-streaming: Available at the time of your choosing for up to 30 days after the concert - $90. Adults In-person: - $130. Students: $25 either in-person or live-streamed. 
        Single Tickets: These will be made available for each concert starting about 30 days before the event.`,

    });

    const georgianMusic = [
        {
            title: 'Andromeda Trio',
            description: 'Tchaikovsky, Shostakovich, Haydn',
            imageUrl: 'images/Georgian-Music/2024-2025/gm_15-09-24.jpg',
            link: '/SingleEvent'
        },
        {
            title: 'Ishay Shaer',
            description: 'Bach, Beethoven, Debussy, Schumann',
            imageUrl: 'images/Georgian-Music/2024-2025/gm_03-11-24.jpg',
            link: '/SingleEvent2'
        },
        {
            title: 'Venuti Quartet',
            description: 'Indigo',
            imageUrl: 'images/Georgian-Music/2024-2025/gm_15-12-24.jpg',
            link: '/SingleEvent3'
        }
    ];
    
    

    return (
        <Box> 
            <MUISingleEventCard
                image={heroSectionEventData.image}
                title={heroSectionEventData.title}
                subtitle={heroSectionEventData.subtitle}
                date={heroSectionEventData.date}
                location={heroSectionEventData.location}
                buttonText={heroSectionEventData.buttonText}
                buttonLink={heroSectionEventData.buttonLink}
            />
            <ArticleCard 
                title={eventData.title}
                subtitle={eventData.subtitle}
                date={eventData.date}
                location={eventData.location}
                program={eventData.program}
                performers={eventData.performers}
                program2={eventData.program2}
                performers2={eventData.performers2}
                performerLink={eventData.performerLink}
                ticketInfo={eventData.ticketInfo}
            />
            <Box marginY='30px'>
                <MUICard2 title="2024/25 Georgian Music" events={georgianMusic} buttonLink="/GeorgianMusic" />
            </Box>
        </Box>
    );
}

export default SingleEvent5;
