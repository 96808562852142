import React, { useState } from 'react';
import { AppBar, Tab, Tabs, Toolbar, Typography, Box, Menu, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import DrawerComp from './Drawer';
import MUIButton from './MUIButton';

const MUInavbar = () => {
    const [value, setValue] = useState(0); // Set default tab value to 0 (Home)
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));

    // State for submenu
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget); // Open submenu when clicking Events tab
    };

    const handleClose = () => {
        setAnchorEl(null); // Close submenu
    };

    // Handle tab change and submenu logic
    const handleChange = (event, newValue) => {
        if (newValue === 1) { // Assuming 'Events' tab index is 1
            handleClick(event); // Open submenu if Events tab is clicked
        } else {
            setValue(newValue); // Switch tabs normally
            handleClose(); // Close the submenu if it is open
        }
    };

    return (
        <React.Fragment>
            <AppBar sx={{ background: "#000000" }}>
                <Toolbar>
                    {isMatch ? (
                        <>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <img src="/images/BCA-logo.jpg" alt="Logo" style={{ height: "30px", marginRight: "5px" }} />
                                <Typography sx={{ fontSize: "1rem", paddingLeft: "5%", color: 'white' }}>
                                    Barrie Concert Association
                                </Typography>
                            </Box>
                            <DrawerComp />
                        </>
                    ) : (
                        <>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <img src="/images/BCA-logo.jpg" alt="Logo" style={{ height: "30px", marginRight: "5px" }} />
                                <Typography sx={{ fontSize: "1rem", paddingLeft: "5%", color: 'white' }}>
                                    Barrie Concert Association
                                </Typography>
                            </Box>
                            <Tabs
                                sx={{ 
                                    marginLeft: 'auto', 
                                    '.MuiTab-root': { color: '#999999' }, 
                                    '.Mui-selected': { color: 'white' } 
                                }}
                                value={value}
                                onChange={handleChange} 
                                TabIndicatorProps={{
                                    style: { display: 'none'} 
                                }}
                            >
                                <Tab label="Home" component={Link} to="/" />
                                <Tab label="Events" aria-controls={open ? 'basic-menu' : undefined} />
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={handleClose} component={Link} to="/Events">All Events</MenuItem>
                                    <MenuItem onClick={handleClose} component={Link} to="/GeorgianMusic">Georgian Music</MenuItem>
                                    <MenuItem onClick={handleClose} component={Link} to="/BarrieConcerts">Barrie Concerts</MenuItem>
                                </Menu>
                                <Tab label="News" component={Link} to="/News" />
                                <Tab label="About" component={Link} to="/About" />
                                <Tab label="Support Us" component={Link} to="/SupportUs" />
                            </Tabs>
                            <a href='https://www.showpass.com/barrie-concerts-and-georgian-music-2024-2025/' target='_blank'>
                                <MUIButton variant="primary">Order Now</MUIButton>
                            </a>
                        </>
                    )}
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
}

export default MUInavbar;
