import React, { useState } from "react";
import '../../App.css';
import ArticleCard from "../ArticleCard";
import MUICard2 from "../MUICard2";
import { Box } from "@mui/material";
import MUISingleEventCard from "../MUISingleEventCard";

function SingleEvent2() {

    const [heroSectionEventData] = useState({
        image: "images/Georgian-Music/2024-2025/gm_03-11-24.jpg",
        title: 'Bach, Beethoven, Debussy, Schumann',
        subtitle: 'Ishay Shaer',
        date: 'Sunday 03 November 2024 3.00pm',
        location: 'Bethel Community Church, 128 St. Vincent St., Barrie',
        buttonText: 'Book Tickets',
        buttonLink: 'https://www.showpass.com/ishay-shaer-on-piano/'
    });

    const [eventData] = useState({
        program: `Ishay Shaer is regarded as one of today’s foremost young Israeli pianists. He has earned numerous accolades, including first prize at the Newport International Competition in the UK, as well as top honors at the "Silvio Bengali" International Competition, the prestigious Clairmont Prize, and the International Beethoven Competition. His upcoming performance will feature the following works:
S. Bach: Partita No. 2 in C minor
Beethoven: Six Bagatelles, Op. 126
Debussy: Images, Book 1
Schumann: Fantasia in C, Op. 17`,
        performers: `Among other venues, he has given recitals at the Queen Elizabeth Hall and the Purcell Room in London, as well as in Bristol and Cheltenham Town Halls, the Poland Philharmonic Hall, in Texas, in Warsaw, and in Tel Aviv, with the BBC National Orchestra of Wales and with major Israeli orchestras. He has broadcast recitals for radio stations in Scotland, Spain, and South America.`,
        performerLink: 'https://www.facebook.com/ishayshaer',
        ticketInfo: `Purchase your season subscription early. 5 amazing upcoming concerts. 
        Adults Live-streaming: Available at the time of your choosing for up to 30 days after the concert - $90. 
        Adults In-person: - $130. 
        Students: $25 either in-person or live-streamed. 
        Single Tickets: These will be made available for each concert starting about 30 days before the event.`,
        performance: `J. S. Bach: Partita No. 2 in C minor 
        L. v. Beethoven: Six Bagatelles, Op. 126 
        C. Debussy: Images, book 1 
        R. Schumann: Fantasia in C, Op. 17`
    });
    

    const georgianMusic = [
        {
            title: 'Andromeda Trio',
            description: 'Tchaikovsky, Shostakovich, Haydn',
            imageUrl: 'images/Georgian-Music/2024-2025/gm_15-09-24.jpg',
            link: '/SingleEvent'
        },
        {
            title: 'Venuti Quartet',
            description: 'Indigo',
            imageUrl: 'images/Georgian-Music/2024-2025/gm_15-12-24.jpg',
            link: '/SingleEvent3'
        },
        {
            title: 'Hall & Farley',
            description: 'A Nightingale Sang',
            imageUrl: 'images/Georgian-Music/2024-2025/gm_02-02-25.jpg',
            link: '/SingleEvent4'
        }
    ];
    

    return (
        <Box> 
            <MUISingleEventCard
                image={heroSectionEventData.image}
                title={heroSectionEventData.title}
                subtitle={heroSectionEventData.subtitle}
                date={heroSectionEventData.date}
                location={heroSectionEventData.location}
                buttonText={heroSectionEventData.buttonText}
                buttonLink={heroSectionEventData.buttonLink} 
            />
            <ArticleCard 
                title={eventData.title}
                subtitle={eventData.subtitle}
                date={eventData.date}
                location={eventData.location}
                program={eventData.program}
                performers={eventData.performers}
                performerLink={eventData.performerLink}
                ticketInfo={eventData.ticketInfo}
                performance={eventData.performance}
            />
            <Box marginY='30px'>
                <MUICard2 title="2024/25 Georgian Music" events={georgianMusic} buttonLink="/GeorgianMusic" />
            </Box>
        </Box>
    );
}

export default SingleEvent2;
